aodocs-document-general-info.header {
  button {
    mat-icon {
      color: var(--color-white) !important;
    }
  }

  &.edit {
    button {
      mat-icon {
        color: var(--color-gray) !important;
      }
    }
  }
}
