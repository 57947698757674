mat-icon {
  &.warning-icon-toast {
    color: var(--color-warning-toast);
  }

  &.success-icon-toast {
    color: var(--color-success-toast);
  }

  &.secondary-icon-toast {
    color: var(--color-secondary-toast);
  }

  &.error-icon-toast {
    color: var(--color-error-toast);
  }
}
