aodocs-field-date.infos > aodocs-field-wrapper,
aodocs-field-string.infos > aodocs-field-wrapper {
  div.field-wrapper {
    margin-bottom: 0 !important;
    padding-right: 0 !important;
    min-width: 0 !important;
  }

  p.value-display {
    font: var(--body-medium-large) !important;
    letter-spacing: var(--letter-spacing-01) !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

aodocs-field-string.infos {
  max-width: 308px;
}

aodocs-user.infos {
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

aodocs-title-field-edit.edit-header > aodocs-field-edit-system-string > aodocs-field-wrapper {
  div.field-wrapper {
    padding-right: 0 !important;

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}
