.mat-mdc-menu-panel {
  border-radius: var(--dim-ssm) !important;

  &.document-menu {
    border-radius: var(--dim-xxxs);
    min-width: 330px !important;

    .mat-mdc-menu-item-highlighted {
      background: var(--surface-1) !important;
      border-radius: 50%;

      .icons {
        color: var(--secondary-50);
      }
    }

    button.icon-button > span {
      display: flex;
      align-items: center;
    }

    button > svg {
      height: 0 !important;
    }

    div.mat-mdc-menu-content {
      margin-right: 0 !important;
      margin-left: 0 !important;

      div.mat-mdc-menu-item {
        padding-left: 0;
        padding-right: 0;
      }
    }

    button.mat-mdc-menu-item {
      padding-right: 0 !important;
    }

    .mat-mdc-menu-item-submenu-trigger {
      padding-left: 0 !important;

      mat-icon {
        margin-right: 0;
      }
    }
  }

  &.user-menu {
    max-width: none !important;

    div.mat-mdc-menu-content {
      margin: 0;
    }

    .user-informations {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 var(--dim-ssm);

      .avatar {
        margin-bottom: var(--dim-sssm);
        width: 63px;
        height: 63px;
        border-radius: var(--border-radius-sidenav-item);
      }

      .username {
        color: var(--color-black);
        font-size: var(--font-size-title);
      }

      .mail {
        color: var(--neutral-50);
        font-size: var(--font-size-text);
      }
    }

    hr {
      height: 1px;
      background-color: var(--neutral-90);
      border: none;
    }
  }

  &.state-menu {
    max-width: 200px !important;

    .mdc-list-item__primary-text {
      white-space: nowrap;
    }
  }
}
