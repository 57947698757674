mat-chip {
  border-radius: var(--dim-xs);
  background-color: var(--surface-variant);
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray);
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin-left: 0 !important;
  margin-right: var(--dim-xxxs) !important;
}

mat-chip-row.ao-chips,
mat-chip-option.ao-chips,
mat-chip.ao-chips {
  background-color: var(--surface-variant) !important;
  border: 1px solid var(--outline);
  color: var(--on-surface-variant);
  font-family: var(--label-large);
  letter-spacing: var(--letter-spacing-01);

  &.standard {
    border-radius: var(--dim-xs) !important;
  }

  .mat-mdc-chip-remove {
    opacity: 1;
  }
}
