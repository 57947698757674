$app-primary: #3e82f7;
$app-error: #ff3333;
$app-grey: #757575;
$app-success: #74bd19;
$app-warning: #ee6e0d;
$app-warning-bg: #fffae1;
$app-background: #f3f3f3;
$app-hover-grey: #e9e9e9;
$app-inactive-grey: #999999;

