@use 'sass:math';

@function px-to-rem($value) {
  @return math.div($value, 16) + rem;
}

:root {
  // font-family
  --font-family-urbanist: Urbanist, sans-serif;
  --font-family-roboto: Roboto, sans-serif;
  --font-family-segoe: 'Segoe UI', sans-serif;

  // font-weight
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;

  // font-size
  --font-size-11: #{px-to-rem(11)};
  --font-size-12: #{px-to-rem(12)};
  --font-size-13: #{px-to-rem(13)};
  --font-size-14: #{px-to-rem(14)};
  --font-size-16: #{px-to-rem(16)};
  --font-size-17: #{px-to-rem(17)};
  --font-size-18: #{px-to-rem(18)};
  --font-size-20: #{px-to-rem(20)};
  --font-size-22: #{px-to-rem(22)};
  --font-size-24: #{px-to-rem(24)};
  --font-size-28: #{px-to-rem(28)};
  --font-size-32: #{px-to-rem(32)};
  --font-size-36: #{px-to-rem(36)};
  --font-size-45: #{px-to-rem(45)};
  --font-size-57: #{px-to-rem(57)};

  // font-size-icon
  --font-size-icon-74: #{px-to-rem(74)};

  // line-height
  --line-height-16: #{px-to-rem(16)};
  --line-height-18: #{px-to-rem(18)};
  --line-height-20: #{px-to-rem(20)};
  --line-height-22: #{px-to-rem(22)};
  --line-height-24: #{px-to-rem(24)};
  --line-height-28: #{px-to-rem(28)};
  --line-height-32: #{px-to-rem(32)};
  --line-height-36: #{px-to-rem(36)};
  --line-height-40: #{px-to-rem(40)};
  --line-height-44: #{px-to-rem(44)};
  --line-height-52: #{px-to-rem(52)};
  --line-height-64: #{px-to-rem(64)};

  // letter-spacing
  --letter-spacing-01: #{px-to-rem(0.1)};
  --letter-spacing-015: #{px-to-rem(0.15)};
  --letter-spacing-025: #{px-to-rem(0.25)};
  --letter-spacing-04: #{px-to-rem(0.4)};
  --letter-spacing-05: #{px-to-rem(0.5)};

  // typographies
  --font-general-info-user: normal var(--font-weight-400) var(--font-size-16) / var(--line-height-20) var(--font-family-roboto);
  --font-document-title-header: normal var(--font-weight-600) var(--font-size-16) / var(--line-height-20) var(--font-family-roboto);
  --font-general-info-title: normal var(--font-weight-600) var(--font-size-16) / var(--line-height-20) var(--font-family-urbanist);
  --font-fake-chip: normal var(--font-weight-500) var(--font-size-13) / var(--line-height-28) var(--font-family-roboto);
  --font-breadcrumb: normal var(--font-weight-600) var(--font-size-20) / var(--line-height-20) var(--font-family-urbanist);
  --font-toolbar: normal var(--font-weight-600) var(--font-size-20) / var(--line-height-20) var(--font-family-urbanist);
  --headline-em-tiny: normal var(--font-weight-600) var(--font-size-20) / var(--line-height-28) var(--font-family-urbanist);
  --headline-em-small: normal var(--font-weight-400) var(--font-size-24) / var(--line-height-32) var(--font-family-urbanist);
  --display-large: normal var(--font-weight-400) var(--font-size-57) / var(--line-height-64) var(--font-family-urbanist);
  --display-medium: normal var(--font-weight-400) var(--font-size-45) / var(--line-height-52) var(--font-family-urbanist);
  --display-small: normal var(--font-weight-400) var(--font-size-36) / var(--line-height-44) var(--font-family-urbanist);
  --headline-large: normal var(--font-weight-400) var(--font-size-32) / var(--line-height-40) var(--font-family-roboto);
  --headline-medium: normal var(--font-weight-400) var(--font-size-28) / var(--line-height-36) var(--font-family-roboto);
  --headline-small: normal var(--font-weight-400) var(--font-size-24) / var(--line-height-32) var(--font-family-roboto);
  --headline-em-large: normal var(--font-weight-400) var(--font-size-32) / var(--line-height-40) var(--font-family-urbanist);
  --headline-em-medium: normal var(--font-weight-400) var(--font-size-28) / var(--line-height-36) var(--font-family-urbanist);
  --title-large: normal var(--font-weight-400) var(--font-size-22) / var(--line-height-28) var(--font-family-roboto);
  --title-medium: normal var(--font-weight-500) var(--font-size-16) / var(--line-height-24) var(--font-family-roboto);
  --title-small: normal var(--font-weight-500) var(--font-size-14) / var(--line-height-20) var(--font-family-roboto);
  --title-em-large: normal var(--font-weight-400) var(--font-size-22) / var(--line-height-28) var(--font-family-urbanist);
  --title-em-medium-large: normal var(--font-weight-700) var(--font-size-20) / var(--line-height-28) var(--font-family-urbanist);
  --title-em-medium: normal var(--font-weight-700) var(--font-size-16) / var(--line-height-24) var(--font-family-urbanist);
  --title-em-small: normal var(--font-weight-500) var(--font-size-14) / var(--line-height-20) var(--font-family-urbanist);
  --label-large: normal var(--font-weight-500) var(--font-size-14) / var(--line-height-20) var(--font-family-roboto);
  --label-medium: normal var(--font-weight-500) var(--font-size-12) / var(--line-height-16) var(--font-family-roboto);
  --label-small: normal var(--font-weight-500) var(--font-size-11) / var(--line-height-16) var(--font-family-roboto);
  --body-large: normal var(--font-weight-500) var(--font-size-16) / var(--line-height-24) var(--font-family-roboto);
  --body-medium-large: normal var(--font-weight-400) var(--font-size-14) / var(--line-height-20) var(--font-family-roboto);
  --body-medium: normal var(--font-weight-400) var(--font-size-13) / var(--line-height-18) var(--font-family-roboto);
  --body-small: normal var(--font-weight-400) var(--font-size-12) / var(--line-height-16) var(--font-family-roboto);

  /* Doc editor sections */
  --section-properties-title: normal var(--font-weight-500) var(--font-size-17) / var(--line-height-20) var(--font-family-urbanist);
  --section-title: normal var(--font-weight-400) var(--font-size-20) / var(--line-height-22) var(--font-family-urbanist);

  /* Doc editor sidenav */
  --title-menu: normal var(--font-weight-500) var(--font-size-14) / var(--line-height-22) var(--font-family-roboto);

  /* Sign in custom font */
  --font-properties-label: normal var(--font-weight-600) var(--font-size-13) / var(--line-height-16) var(--font-family-roboto);
  --font-sign-in-microsoft: normal var(--font-weight-600) var(--font-size-14) / var(--line-height-20) var(--font-family-segoe);
  --font-sign-in-google: normal var(--font-weight-600) var(--font-size-14) / var(--line-height-20) var(--font-family-roboto);

  /* Datepicker fonts */
  --font-datepicker-cell: normal var(--font-weight-400) var(--font-size-14) / var(--line-height-20) var(--font-family-roboto);
  --font-timepicker-hour: normal var(--font-weight-400) var(--font-size-57) / var(--line-height-64) var(--font-family-roboto);
  --font-timepicker-clock-number: normal var(--font-weight-400) var(--font-size-16) / var(--line-height-24) var(--font-family-roboto);
}
