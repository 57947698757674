@use 'sass:math';

:root {
  --border-radius: var(--dim-sm);
  --border-radius-md: var(--dim-md);
  --border-radius-sm: var(--dim-xs);
  --border-radius-ssm: var(--dim-ssm);
  --border-radius-sidenav-item: 50px;
  --border-default: var(--dim-pixel) solid var(--color-gray-light);
  --border-radius-default: var(--dim-xxs);
  --border-radius-bottom: 0 0 var(--dim-xs) var(--dim-xs);
  --border-radius-top: var(--dim-xs) var(--dim-xs) 0 0;
  --border-radius-half: 50%;
}
