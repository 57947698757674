mat-form-field {
  width: 100%;

  &.aodocs-input {
    width: 100%;

    .mdc-text-field--filled {
      background-color: var(--surface-variant);
    }

    .mat-mdc-form-field-infix {
      min-height: 44px;
      width: 100%;
      padding: var(--dim-14) 0 var(--dim-sssm) !important;
      font: var(--body-medium-large);
      color: var(--on-surface);
    }

    .ao-cursor-text {
      cursor: text;
    }

    &.aodocs-no-subscript {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    &.aodocs-input-url-text {
      .mat-mdc-text-field-wrapper {
        border-radius: 0;
      }

      .mat-mdc-form-field-infix {
        line-height: 100%;
      }
    }

    &:not(.inline).with-value {
      .mat-mdc-form-field-infix {
        padding: 0 0 var(--dim-sssm) !important;
      }

      input {
        flex-basis: 100%;
      }
    }

    &.inline.with-value {
      .mat-mdc-form-field-infix {
        padding: 0 !important;
      }

      input {
        flex-basis: 100%;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      align-self: flex-start;
      margin-top: var(--dim-xxxs);
      padding: 0;

      & > .mat-icon {
        padding: var(--dim-xs);
      }
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0 !important;
    }

    &.title-input {
      .mdc-text-field__input::placeholder {
        color: var(--color-red);
      }

      .mat-mdc-text-field-wrapper {
        max-height: 35px;
        padding: 0;
      }

      .mdc-text-field--no-label .mat-mdc-form-field-infix {
        padding-left: var(--dim-sm) !important;
      }

      .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
        font: var(--font-general-info-title);
        letter-spacing: var(--letter-spacing-025);
      }

      .mat-mdc-form-field-flex {
        background: transparent;
        padding-left: 0;
        align-items: end;
        height: 40px;
      }

      mat-icon {
        font-size: var(--font-size-20);
        height: 20px;
        width: 20px;
        padding: var(--dim-xxxs) !important;
      }
    }
  }
}

/* stylelint-disable no-descending-specificity */
aodocs-field-edit-category-picker mat-form-field.aodocs-input .mat-mdc-form-field-infix {
  padding: var(--dim-xxxs) 0 !important;
}
/* stylelint-enable no-descending-specificity */

mat-option.ao-option {
  &.loading-option {
    padding: 0 !important;
    height: 4px;
    min-height: 4px;
  }

  .mdc-list-item__primary-text {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .ao-option-prefix {
    color: var(--prefix-color);
  }
}

mat-error {
  line-height: var(--line-height-16);
}
