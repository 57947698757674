button:not([class*='mtx-'], #ao-design, [class*='tree-icon'], [class*='aolh-EditCard'], [class*='aod-Icon--grey'], [class*='toggle-button'], [class*='search-form-button'], [class*='search-clear-button'], [class*='mat-mdc-paginator-navigation-'], [class*='toggle-drawer'], [class*='aov-toolbar-button'], [class*='expand-collapse-button']) {
  &:is([mat-button], [mat-stroked-button], [mat-raised-button], [mat-flat-button]) {
    height: 36px;
    min-width: 64px;
  }

  // fixme: css about buttons has to be reviewed...
  &:is([mat-button], [mat-stroked-button], [mat-raised-button], [mat-flat-button], [mat-icon-button]) {
    [class*='label'] {
      color: inherit;
    }

    mat-icon {
      color: inherit;
      line-height: 1;
    }

    &.no-background {
      background-color: transparent !important;
    }

    &:focus {
      background-color: var(--secondary-80) !important;
    }

    &.warning-toast {
      color: var(--color-warning-toast);
      float: right;
      font: var(--label-large);

      &:hover {
        background-color: transparent !important;
      }

      &:focus {
        background-color: transparent !important;
      }
    }

    &.secondary {
      background-color: var(--secondary-40) !important;
      color: var(--on-secondary) !important;

      &:hover {
        background-color: var(--secondary-30) !important;
      }

      &:focus {
        background-color: var(--secondary-20) !important;
      }

      &:disabled {
        background-color: var(--on-surface-disabled-light) !important;

        [class*='label'] {
          color: var(--on-surface) !important;
          opacity: 0.38;
        }
      }
    }

    &.field-picker-icon {
      padding: 0;
    }

    &.avatar-menu {
      height: 42px;
      width: 42px;
      min-width: 42px;
      border-radius: var(--border-radius-sidenav-item);
    }

    &.cancel {
      background-color: var(--background-color-cancel) !important;
      border: var(--dim-pixel) solid var(--border-color-cancel);
      color: var(--on-surface-variant);

      &:hover {
        background-color: var(--background-color-cancel) !important;
      }

      &:focus {
        background-color: var(--background-color-cancel) !important;
      }

      mat-icon {
        color: var(--on-surface-variant);
        margin-right: var(--dim-xs);
        line-height: 1.1;
        height: 18px;
        width: 18px;
        font-size: var(--dim-mdss);
      }

      [mat-icon-button] {
        width: 36px !important;
        height: 36px !important;
        padding: var(--dim-xxs) !important;
      }
    }

    &.save {
      background-color: var(--save-color) !important;
      color: var(--on-primary) !important;
      border: var(--dim-pixel) solid var(--save-color);
      transition: border-color ease-in-out 300ms, background-color ease-in-out 300ms, opacity ease-in-out 300ms;

      &:hover {
        background-color: var(--save-color-hover) !important;
      }

      &:focus {
        background-color: var(--save-color-focus) !important;
      }

      &:disabled {
        background-color: var(--on-surface-variant) !important;
        opacity: 0.2;
        border-color: var(--on-surface-variant);
      }

      mat-icon {
        color: var(--on-primary) !important;
        margin-right: var(--dim-xs);
        line-height: 1.1;
        height: 18px;
        width: 18px;
        font-size: var(--dim-mdss);
      }

      &.confirm {
        background-color: var(--secondary-40) !important;
        color: var(--on-secondary) !important;
        border: none;
      }
    }

    &.main {
      background-color: var(--secondary-40) !important;
      color: var(--on-secondary) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font: var(--title-small);
      height: 50px;
      border-radius: var(--border-radius-ssm);

      &:hover {
        background-color: var(--secondary-30) !important;
      }

      &:focus {
        background-color: var(--secondary-20) !important;
      }

      mat-icon {
        color: var(--on-secondary) !important;
        margin-right: var(--dim-xs);
        height: 18px;
        width: 18px;
        font-size: var(--dim-mdss);
      }

      &.dropdown {
        padding-right: var(--dim-xxs) !important;

        span.mdc-button__label {
          display: flex;
          justify-content: center;
        }

        svg {
          fill: var(--color-white) !important;
        }
      }
    }

    &.draft {
      background-color: var(--color-gray-dark) !important;
      color: var(--on-secondary) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font: var(--title-small);
      height: 50px;
      border-radius: var(--border-radius-ssm);

      &:hover {
        background-color: var(--on-color-gray-dark) !important;
      }

      &:focus {
        background-color: var(--on-color-gray-dark) !important;
      }

      mat-icon {
        color: var(--on-secondary) !important;
        margin-right: var(--dim-xs);
        height: 18px;
        width: 18px;
        font-size: var(--dim-mdss);
      }

      &.dropdown {
        padding-right: var(--dim-xxs) !important;

        span.mdc-button__label {
          display: flex;
          justify-content: center;
        }

        svg {
          fill: var(--color-white) !important;
        }
      }
    }

    &.edit {
      background-color: var(--secondary-40) !important;
      color: var(--on-secondary) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font: var(--title-small);

      &:hover {
        background-color: var(--secondary-30) !important;
      }

      &:focus {
        background-color: var(--secondary-20) !important;
      }

      mat-icon {
        color: var(--on-secondary) !important;
        margin-right: var(--dim-xs);
        height: 18px;
        width: 18px;
        font-size: var(--dim-mdss);
      }

      &.dropdown {
        padding-right: var(--dim-xxs) !important;

        svg {
          fill: var(--color-white) !important;
        }
      }
    }

    &.prevent {
      background-color: var(--color-white) !important;
      color: var(--neutral-variant-30) !important;
      border: solid 1px;
      border-color: var(--neutral-variant-80);

      mat-icon.alert-icon {
        width: 24px;
        height: 24px;
      }

      .mdc-button__label {
        height: 100%;
      }

      &.dropdown {
        padding-left: var(--dim-sssm) !important;
        padding-right: var(--dim-xxs) !important;

        svg {
          fill: var(--neutral-variant-30) !important;
        }
      }
    }

    &.icon-header {
      background-color: transparent !important;
    }

    &.header-switch {
      background-color: transparent !important;
      border: none;
      width: 30px;
      padding: 0;

      &:hover {
        background-color: transparent !important;
      }

      &:focus {
        background-color: transparent !important;
      }

      .mat-mdc-button-persistent-ripple {
        display: none;
      }

      mat-icon {
        color: var(--color-gray-dark);
      }
    }

    &.toolbar-other-action {
      font: var(--label-large);
      padding: var(--dim-xs);
      background-color: transparent !important;
      border: none;

      &:hover {
        background-color: transparent !important;
      }

      &:focus {
        background-color: transparent !important;
      }

      mat-icon {
        color: var(--secondary-40);
        line-height: 1.1;
        height: 18px;
        width: 18px;
        font-size: var(--dim-mdss);

        &:first-of-type {
          margin-right: var(--dim-xs);
        }

        &:last-of-type {
          margin-left: var(--dim-xs);
        }
      }
    }

    &.toolbar-expand-collapse-sidenav {
      background-color: transparent !important;
      min-width: 0;
      width: 60px;
      padding: 0;

      &:hover {
        background-color: transparent !important;
      }

      &:focus {
        background-color: transparent !important;
      }
    }

    &.navigation-button {
      background-color: transparent !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font: var(--title-small);
      padding: unset;
      border: none;

      mat-icon:first-of-type {
        color: var(--on-background);
        height: 28px;
        width: 20px;
        font-size: var(--font-size-20);
        line-height: calc(var(--dim-lg) - var(--dim-pixel));
      }

      &[disabled] {
        cursor: default;

        mat-icon {
          color: var(--neutral-80);
        }
      }
    }
  }
}
