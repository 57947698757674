mat-toolbar {
  &.document-toolbar {
    background: var(--color-white);
    height: 48px;
    border-radius: var(--dim-ssm);
    gap: var(--dim-sm);
    padding: 0 var(--dim-mds);

    mat-divider {
      height: 100%;
    }

    .spacer {
      flex: 1 1 auto;
    }
  }

  &.document-toolbar-layout {
    background: var(--surface-1);
    padding: 0;
  }
}
