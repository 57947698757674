mat-datepicker-content {
  &.mat-datepicker-content {
    border-radius: var(--border-radius);
  }

  mat-calendar {
    &.doc-editor-datepicker {
      background-color: var(--datepicker-background);
      border-radius: var(--border-radius);

      .mat-calendar-header {
        button {
          background-color: transparent !important;
          color: var(--color-header-grey);

          .mdc-button__label {
            color: var(--color-header-grey);
          }

          .mat-calendar-arrow {
            fill: var(--color-header-grey);
          }
        }
      }

      .mat-calendar-body-selected {
        background-color: var(--primary-40);
      }

      .mat-calendar-body-today {
        background-color: transparent !important;
        color: var(--primary-40);
        border-color: var(--primary-40) !important;
      }

      .mat-calendar-body-label {
        padding-top: 0 !important;
        visibility: hidden;
      }

      .mat-calendar-body-cell {
        color: var(--neutral-10);
        font: var(--font-datepicker-cell) !important;

        &.hover {
          .mat-calendar-body-cell-content {
            background-color: var(--datepicker-cell-hover) !important;
            color: var(--neutral-10);
          }
        }

        &.mat-calendar-body-active {
          .mat-calendar-body-cell-content:not(.mat-calendar-body-today, .mat-calendar-body-selected) {
            background-color: var(--primary-80) !important;
          }
        }
      }

      .mat-calendar-table-header {
        color: var(--neutral-variant-30);
        font: var(--label-medium);
      }

      .mat-calendar-previous-button::after,
      .mat-calendar-next-button::after {
        margin: var(--dim-14);
      }
    }
  }
}

mat-datepicker-toggle.datepicker-toggle {
  color: var(--neutral-variant-30);

  button.mdc-icon-button,
  button.mdc-icon-button:hover,
  button.mdc-icon-button:focus {
    background-color: transparent !important;
  }

  .mat-mdc-button-touch-target,
  .mdc-icon-button {
    width: 44px;
    height: 44px;
    padding: var(--dim-sssm);
    color: var(--on-surface-variant);
  }

  &.mat-datepicker-toggle-active {
    .mat-mdc-button-touch-target,
    .mdc-icon-button {
      color: var(--primary-40);
    }
  }
}
