mat-card.home-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: none;
  height: 218px;
  width: 230px;
  border-radius: var(--border-radius);
  padding: var(--dim-sm);
  background: var(--color-white);
  gap: var(--dim-xs);
  cursor: pointer;

  &:hover {
    background: var(--secondary-95);
  }

  &.active {
    background: var(--secondary-90);
  }

  .icons {
    font-size: var(--font-size-icon-74);
    height: 74px;
    width: 74px;
    color: var(--secondary);
  }

  h2 {
    font: var(--title-em-medium-large);
    text-align: center;
  }

  mat-card-content {
    font: var(--title-em-small);
    padding: 0;
    text-align: center;
  }
}
