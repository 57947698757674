mat-expansion-panel:is(.properties-panel, .description-section, .attachments-section) {
  color: var(--color-black);

  & > mat-expansion-panel-header {
    h4 {
      font: var(--section-properties-title);
      letter-spacing: var(--letter-spacing-01);
      height: 20px;
      max-width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      margin-left: var(--dim-ssm);
    }

    mat-icon.arrow {
      margin-left: auto;
      margin-right: calc(var(--dim-xxs) * -1);
    }
  }
}

mat-expansion-panel:is(.properties-panel, .custom-section, .description-section, .attachments-section) {
  mat-expansion-panel-header {
    margin: 0 !important;
    height: 48px !important;
    padding: 0 var(--dim-mds) 0 var(--dim-md);

    .mat-content {
      display: flex;
      align-items: center;
      overflow: visible;
    }

    .arrow {
      transition: rotate ease-in-out 300ms;
      flex-shrink: 0;
    }

    &.mat-expanded .arrow {
      rotate: -180deg;
    }

    .help-icon {
      margin-left: var(--dim-xs);
      width: 20px;
      height: 20px;
      font-size: var(--dim-mds);
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  &.custom-section {
    color: var(--primary-40);

    & > mat-expansion-panel-header {
      .mat-content {
        min-width: 0;

        h4 {
          margin-left: var(--dim-xxs);
          font: var(--section-title);
          letter-spacing: var(--letter-spacing-01);
          height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        mat-icon.arrow {
          margin-left: auto;
          margin-right: calc(calc(var(--dim-xxs) + var(--dim-pixel)) * -1);
        }
      }
    }

    .mat-expansion-panel-body {
      padding: var(--dim-xxs) var(--dim-sm) var(--dim-md) var(--dim-xxxl) !important;

      div.section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &.description-section {
    & > mat-expansion-panel-header {
      .container {
        display: flex;
        gap: var(--dim-xxxs);
        align-items: center;
      }
    }

    .mat-expansion-panel-body {
      padding: 0 var(--dim-xxxl) var(--dim-xxl) var(--dim-xxxl) !important;
    }
  }

  &.properties-panel {
    & > mat-expansion-panel-header.mat-expanded mat-icon.arrow {
      margin-left: initial;
      margin-right: calc(var(--dim-xxs) * -1);
    }

    div.properties-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: var(--dim-xxs) var(--dim-sm) var(--dim-md) var(--dim-xxxl) !important;
    }
  }
}
