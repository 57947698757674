mat-slide-toggle {
  &:has(button[disabled]) {
    filter: var(--toggle-field-opacity);
  }

  &.small-toggle {
    transform: scale(0.8) translateX(-12.5%);
  }

  button {
    width: 44px !important;

    &:not(.mdc-switch--checked) {
      border: var(--dim-xxxxs) solid var(--outline) !important;
      border-radius: var(--dim-xxl) !important;
      background: var(--surface-variant) !important;

      .mdc-switch__track {
        height: 20px !important;
        border-radius: var(--dim-xxl) !important;

        &::before,
        &::after {
          background-color: var(--surface-variant) !important;
        }
      }

      .mdc-switch__handle-track {
        .mdc-switch__handle {
          height: 16px !important;
          width: 16px !important;
          left: 2px;
          border-radius: var(--dim-xxl) !important;

          .mdc-switch__shadow {
            background: var(--outline) !important;
            box-shadow: none !important;
          }

          .mdc-switch__ripple {
            .mat-ripple::after {
              background-color: var(--primary-60);
            }
          }

          .mdc-switch__icons {
            display: none;
          }
        }
      }
    }

    &.mdc-switch--checked {
      border-radius: var(--dim-xxl) !important;

      .mdc-switch__track {
        height: 20px !important;
        border-radius: var(--dim-xxl) !important;
      }

      .mdc-switch__handle-track {
        .mdc-switch__handle {
          height: 20px !important;
          width: 20px !important;
          border-radius: var(--dim-xxl) !important;

          .mdc-switch__shadow {
            background: var(--color-white) !important;
            box-shadow: none !important;
          }

          .mdc-switch__ripple::after {
            background-color: var(--primary-60) !important;
          }

          .mdc-switch__icons {
            display: none;
          }
        }
      }
    }
  }
}
