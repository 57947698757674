mat-tooltip-component {
  .user-tooltip,
  .attachment-tooltip {
    .mdc-tooltip__surface {
      max-width: unset !important;
    }
  }

  .multiline-tooltip {
    .mdc-tooltip__surface {
      text-align: left !important;
      max-width: unset !important;
    }

    white-space: pre-line !important;
  }

  .long-tooltip {
    .mdc-tooltip__surface {
      text-align: left !important;
      font-size: .7rem;
      max-width: 20rem !important;
      max-height: unset !important;
    }

    white-space: pre-line !important;
  }

  .general-info {
    .mdc-tooltip__surface {
      max-height: unset !important;
    }
  }
}
