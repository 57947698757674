.mtx-datetimepicker-content .mtx-calendar {
  &.mtx-calendar-with-time-input {
    height: 356px !important;
  }
}

.mtx-calendar-content {
  background-color: var(--datepicker-background);
  height: 100%;
}

.mtx-calendar-header {
  display: none;
}

.mtx-time-input-wrapper {
  .mtx-time-input {
    background-color: var(--neutral-variant-90);
    color: var(--neutral-10);

    &.mtx-time-input-active,
    &.mtx-time-input-active:focus {
      background-color: var(--primary-90);
      color: var(--primary-10);
    }

    &.mtx-time-input-active:focus {
      border-color: var(--primary-40);
    }
  }
}

.mtx-clock-wrapper {
  background-color: var(--neutral-variant-90);
}

.mtx-clock-cell.mtx-clock-cell-selected,
.mtx-clock-hand,
.mtx-clock-hand::before,
.mtx-clock-center {
  background-color: var(--primary-40);
}

.mtx-time-button-wrapper .mat-mdc-button:not(:disabled) {
  color: var(--secondary-40, inherit);
}

mtx-datetimepicker-toggle.mtx-datetimepicker-toggle {
  color: var(--neutral-variant-30);

  button.mdc-icon-button,
  button.mdc-icon-button:hover,
  button.mdc-icon-button:focus {
    background-color: transparent !important;
  }

  .mat-mdc-button-touch-target,
  .mdc-icon-button {
    width: 44px;
    height: 44px;
    color: var(--on-surface-variant);
    padding: var(--dim-sssm);
  }

  &.mtx-datetimepicker-toggle-active {
    .mat-mdc-button-touch-target,
    .mdc-icon-button {
      color: var(--primary-40);
    }
  }
}

.mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected).mtx-calendar-body-today {
  border-color: var(--primary-40);
  color: var(--primary-40);
  background-color: transparent;
}

.mtx-calendar-body-active > .mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected, .mtx-calendar-body-today) {
  background-color: var(--primary-80);
}

.mtx-calendar-body-cell:not(.mtx-calendar-body-disabled):hover > .mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected) {
  background-color: var(--datepicker-cell-hover);
}

.mtx-calendar-body-cell-content.mtx-calendar-body-selected {
  background-color: var(--primary-40);
}

.mtx-clock {
  width: 224px;
  margin: auto !important;
}
