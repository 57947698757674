@use '@angular/material' as mat;
@use '../tokens/primitive/primary';
@use '../tokens/primitive/accent';
@include mat.core;

$primary: mat.define-palette(primary.$aodocs-primary-palette, 600, 200, 900);
$accent: mat.define-palette(accent.$aodocs-accent-palette, A100, A100, A700);
$warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Note: Palette contrast don't apply, we need to made it manual until they will release the fix
// Issue: https://github.com/angular/components/issues/26056

.mat-mdc-mini-fab.mat-accent.mat-accent {
  --mdc-filled-button-label-text-color: #3d6fe2;
  --mdc-fab-icon-color: #3d6fe2;
  --mat-mdc-fab-color: #3d6fe2;
}

@include mat.core-theme($theme);
@include mat.all-component-themes($theme);
