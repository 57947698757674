/* You can add global styles to this file, and also import other style files */
@import 'ui/sass/style';
@import 'ui/components/style';
@import 'ui/tokens/tokens-animation';
@import 'ui/tokens/tokens-border';
@import 'ui/tokens/tokens-filters';
@import 'ui/tokens/tokens-font';
@import 'ui/tokens/tokens-margin';
@import 'ui/tokens/tokens-padding';
@import 'ui/tokens/tokens-shadow';
@import 'ui/tokens/tokens-size';
@import 'ui/tokens/token-icon';
@import 'ui/tokens/token-material';
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Urbanist:wght@200;300;400;500;600;700&display=swap';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';
@import 'ui/themes/material-palette';

@import "ui/colors";
@import "ui/layout";

html, body {
  background-color: $app-background;
  height: 100vh;
  width: 100vw;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
caption,
tbody,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

details > summary {
  list-style: none;
}

q {
  quotes: none;
}

q::before,
q::after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  line-height: 1;
}

body,
body .p-component {
  font-family: var(--font-family-urbanist);
}

a.menu-item {
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: currentColor;
}

.aodocs-label {
  font: var(--font-properties-label);
  letter-spacing: var(--letter-spacing-04);
  color: var(--primary-50);
  margin-bottom: var(--dim-xxxs);
}

.aodocs-input {
  mat-icon {
    color: var(--on-surface-variant);
    font-size: var(--font-size-20);
    width: 20px;
    height: 20px;

    &.pointer {
      cursor: pointer;
    }
  }

  .float-right {
    float: right;
  }

  mat-hint {
    font: var(--body-small);
    color: var(--on-surface-variant);
  }

  p {
    color: var(--on-surface);
    font: var(--body-medium-large);

    &:not(:last-child) {
      margin-bottom: var(--dim-ssm);
    }
  }
}

.aodocs-input-with-label {
  input {
    padding-top: 10px;
  }
}


.mat-mdc-form-field.input-search {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-icon-suffix {
    padding: 0;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mdc-text-field--filled {
    border-radius: var(--border-radius-md);
    &:not(.mdc-text-field--disabled) {
      background-color: var(--primary-100);

      .mat-mdc-form-field-focus-overlay {
        background-color: var(--primary-100);
      }
    }
  }

}

.no-padding {
  padding: 0 !important;
}

.default-padding {
  padding: 0 var(--padding-large);
}

.no-margin {
  margin: 0 !important;
}

.full-width {
  width: 100%;
}

.grey-label {
  color: rgba(0, 0, 0, .54);
  font-size: 13px;
  font-weight: 500;
}

.blue-label {
  color: $app-primary;
  font-weight: 400;
}


table mat-cell button.table-button, table td button.table-button, table mat-cell a.table-button, table td a.table-button, {
  min-width: 6px;
  padding: 0 0;
  color: $app-grey;

  svg {
    fill: $app-grey;
  }
}

.spinner-container .mat-progress-spinner circle, .spinner-container .mat-spinner circle {
  stroke: white
}

.spinner-container.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: 9px 50px 0 0;
  padding:0
}


.green {
  color: $app-success;
}

.red {
  color: $app-error;
}

.blue {
  color: $app-primary;
}

.grey {
  color: $app-grey;
}

.pointer {
  cursor: pointer;
}

.fw-500 {
  font-weight: 500;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.label-success {
  border-radius: 7px;
  color: white;
  padding: 4px;
  background-color: $app-success;
}

.label-grey {
  border-radius: 7px;
  color: white;
  padding: 4px;
  background-color: $app-inactive-grey;
}

.label-warning {
  border-radius: 7px;
  color: white;
  padding: 4px;
  background-color: $app-warning;
}

.label-error {
  border-radius: 7px;
  color: white;
  padding: 4px;
  background-color: $app-error;
}

.label-default {
  border-radius: 7px;
  color: white;
  padding: 4px;
  background-color: $app-primary;
}

#rule-creator-editor-content mat-divider {
  visibility: hidden;
}


.mat-mdc-table {
  overflow-x: scroll;
  .mat-mdc-row {
    align-items: center;
    min-width: max-content;
  }

  .mat-mdc-no-data-row {
    .mat-cell {
      text-align: center;
      padding: 2em;
      font-size: large;
    }
  }

  .mat-mdc-header-cell, .mat-mdc-footer-cell {
    padding-right: 10px;

    &:last-of-type {
      padding-right: 24px;
    }

    &.sm-cell {
      max-width: 100px;
      min-width: 100px;
    }

    &.md-cell {
      max-width: 180px;
      min-width: 120px;
    }

    &.lg-cell {
      max-width: 220px;
      min-width: 180px;
    }

    &.xlg-cell {
      max-width: 310px;
      min-width: 220px;
    }

    &.xxl-cell {
      max-width: 450px;
      min-width: 310px;
    }

    span {
      width: 100%;
    }
  }
  .mat-mdc-cell {
    align-items: center;
    padding-right: 10px;

    &:last-of-type {
      padding-right: 24px;
    }

    &.sm-cell {
      max-width: 100px;
      min-width: 100px;
    }

    &.md-cell {
      max-width: 180px;
      min-width: 120px;
    }

    &.lg-cell {
      max-width: 220px;
      min-width: 180px;
    }

    &.xlg-cell {
      max-width: 310px;
      min-width: 220px;
    }

    &.xxl-cell {
      max-width: 450px;
      min-width: 310px;
    }

    span {
      width: 100%;
    }
  }
}

.stepper-container{
  width: 250px;
}

.main-content {
  padding: 2em 2em 2em 1em;

  .card-container {
    flex: 1 1 0;
    overflow: hidden;


    mat-card {
      max-height: 100%;
      display: flex;
      flex-direction: column;
      .table-section {
        height: 100%;
        width: 100%;
        overflow: auto;
        position: relative;
      }
    }
  }

  .header-section {
    margin-bottom: 20px;
    display: flex;
    column-gap: 2em;
    row-gap: 1em;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .title-section {
      flex-grow: 999;

      h1 {
        margin: 0;
      }

      .arrow-back {
        cursor: pointer;
        vertical-align: middle;
        margin-right: 1em;

        .mat-icon {
          margin-top: 5px;
        }
      }
    }

    .actions-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 2em;
      row-gap: 1em;
      flex-grow: 1;

      &.center {
        align-items: center;
      }

      .filter-section {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        column-gap: 1em;
        row-gap: 1em;
        align-items: center;
      }

      .buttons-section {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        column-gap: 1em;
      }
    }
  }
}
